import {
    Cast,
    CastType,
    IDataModel,
    Ignore,
    MethodType,
    Model,
    Nullable,
} from '@klickdata/core/application/src/model/model-interface';
import { TaskData } from '@klickdata/core/task';
import { User } from '@klickdata/core/user/src/user.model';
import { Utils } from '@klickdata/core/util';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { CustomerCategory } from './customer-category/customer-category.model';
import { CustomerLicense } from './customer-license/customer-license.model';
import { CustomerServiceModel } from './customer-service/customer-service.model';
import { Medias } from '@klickdata/core/resource/src/resource.model';
import { CustomField, CustomFieldData } from '@klickdata/core/custom-field';
import { AppScope, ResourceType } from '@klickdata/core/resource';

export interface FXR {
    from: string;
    to: string;
    fxr: number;
}

export interface SocialMedia {
    facebook: string;
    twitter: string;
    linkedin: string;
    instagram: string;
}
export interface CustomerData extends IDataModel {
    id?: number;
    name?: string;
    short_name?: string;
    subdomains?: string[];
    domains?: string[];
    lang?: string;
    pref_lang_ids?: number[];
    published?: string;
    category_value?: string;
    service_values?: string[];
    expiration_date?: string;
    max_users?: number;
    customer_number?: number;
    license_type?: string;
    notes?: string;
    welcome_message?: { [key: string]: string };
    about_us?: { [key: string]: string };
    currency_fxr?: FXR[];
    social_media?: SocialMedia;
    communications?: {};
    connections?: {};
    address?: {};
    styles?: {};
    logotype_id?: string;
    landing_background_media_id?: string;
    url?: string;
    logotype_url?: string;
    background_url?: string;
    logotype_padding?: number;
    prompt_delimiters_disabled?: boolean;
    prompt_delimiters_master_disabled?: boolean;
    enable_anonymous_register?: boolean;
    enable_subscription_plans?: boolean;

    created_by?: string;
    created_at?: string;
    updated_at?: string;
    users_count?: number;

    // assign param
    sync_param?: { published?: boolean; public?: boolean; customer_id?: number; scope_id: number };
    cluster_count?: number;
    priority?: any;
    card?: boolean;
    note?: string;
    type?: string;
    staff?: { [key: string]: any };
    task?: TaskData;
    loading?: boolean;
    customer_size?: string;
    medias?: Medias;
    managers?: User[];
    custom_fields_ids?: number[];
    custom_fields?: CustomFieldData[];
    public_approval_required?: boolean;
    publish_approval_required?: boolean;
    authoring_allowed_types?: AppScope[];
    division_mode?: boolean;
    recurring?: { resource_id?: number; metadata?: { recurring?: string; start_date?: string } };
}

export class Customer extends Model<CustomerData> {
    id: number;
    name: string;
    short_name: string;
    @Nullable()
    subdomains: string[];
    @Nullable()
    domains: string[];
    lang: string;
    pref_lang_ids: number[];
    @Cast(CastType.MOMENT)
    @Nullable()
    published: moment.Moment;
    category_value: string;
    service_values: string[];

    @Nullable(MethodType.POST)
    max_users: number;
    customer_number: number;
    license_type: string;
    notes: string;
    loading: boolean;
    card: boolean;
    @Cast(CastType.OBJECT)
    welcome_message: { [key: string]: string };
    @Cast(CastType.OBJECT)
    styles: { [key: string]: any };
    @Cast(CastType.OBJECT)
    about_us: { [key: string]: string };
    currency_fxr: FXR[];
    social_media: SocialMedia;
    @Cast(CastType.OBJECT)
    communications: { [key: string]: any };
    @Cast(CastType.OBJECT)
    address: { [key: string]: any };
    addresses: { [key: string]: any }[];
    @Cast(CastType.OBJECT)
    staff: { [key: string]: any };
    logotype_id: number;
    landing_background_media_id: number;
    logotype_padding: number;
    customer_size: string;
    task: TaskData;
    @Cast(CastType.MOMENT)
    @Ignore()
    created_at: moment.Moment;
    @Cast(CastType.MOMENT)
    @Ignore()
    last_login: moment.Moment;
    priority: any;
    @Cast(CastType.MOMENT)
    @Ignore()
    updated_at: moment.Moment;
    type: string;
    employees_count: number;

    @Cast(CastType.MOMENT, 'YYYY-MM-DD')
    @Nullable()
    expiration_date: moment.Moment;

    readonly created_by: string;
    readonly createdAt: Date;
    readonly updatedAt: Date;
    custom_fields: CustomField[];
    custom_fields_ids: number[];
    @Ignore()
    services_attached: boolean;

    @Ignore()
    checked: boolean;
    @Ignore()
    users_count: number;
    enable_anonymous_register: boolean;
    enable_subscription_plans: boolean;
    prompt_delimiters_disabled: boolean;
    prompt_delimiters_master_disabled: boolean;

    services: Observable<CustomerServiceModel[]>;
    license: Observable<CustomerLicense>;
    category: Observable<CustomerCategory>;
    administrators: Observable<User[]>;
    manager$: Observable<User>;
    cluster_count: number;
    @Cast(CastType.CLOSURE, Utils.mediasPayload)
    medias: Medias;
    @Ignore()
    managers: User[];
    public_approval_required: boolean;
    publish_approval_required: boolean;
    authoring_allowed_types: AppScope[];
    division_mode: boolean;
    @Ignore()
    scope_names: { [key: number]: string };

    /**
     * Object.assign() is used to create a shallow copy of this object and assign it to a new object obj.
     * const obj = Object.assign(<CustomerData>{}, this);
     *
     * A deep copy means that all nested objects and arrays are also copied, rather than just copying their references.
     * One way to create a deep copy is by using JSON.parse() and JSON.stringify()
     */
    get value(): CustomerData {
        const obj: CustomerData = JSON.parse(JSON.stringify(this));
        // if (Utils.isEmpty(obj.domains)) obj.domains = [Utils.getDomain()];
        obj.pref_lang_ids = obj.pref_lang_ids?.length ? obj.pref_lang_ids : [1]; // default en-US
        obj.priority = obj.priority ?? 'a3'; // default A3
        return obj;
    }

    get adminIds(): number[] {
        const ids = [];
        if (this.staff?.manager) ids.push(this.staff?.manager);
        if (this.staff?.enterprise_support) ids.push(this.staff?.enterprise_support);
        return ids;
    }

    get lightCommunications(): { [key: string]: any } {
        const obj = this.communications;
        if (Utils.isEmpty(obj)) return [];
        const light = {};
        Object.keys(obj).forEach((communicationType) => {
            if (!!obj[communicationType]?.length) {
                light[communicationType] = obj[communicationType][0].value;
            }
        });
        return light;
    }

    get activeOrderedCustomFields(): CustomField[] {
        return this.custom_fields?.sort((a, b) => a.order - b.order)?.filter((item) => !item.disabled) ?? [];
    }

    getFXR(from: string, to: string): FXR | null {
        if (!this.currency_fxr) {
            return null;
        }

        const directFXR = this.currency_fxr.find((fxr) => from === fxr.from && to === fxr.to);
        const reverseFXR = this.currency_fxr.find((fxr) => from === fxr.to && to === fxr.from);

        return directFXR ?? reverseFXR ?? null;
    }
}
export interface CustomerLanding {
    title?: string;
    logotype_padding?: number;
    logotype_url?: string;
    footerLogotype?: string;
    background_url?: string;
    enable_anonymous_register?: boolean;
    welcome_message?: string;
    styles?: any;
}
