import { Injectable } from '@angular/core';
import { BreakPointRegistry, MediaChange, MediaObserver } from '@angular/flex-layout';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
export enum SideNaveDataTypes {
    CLEARDRAWER = 'clearDrawer',
    MESSAGE_ACTIONS = 'messageAction',
    ALERT = 'alert',
    MOBILE_MENU = 'mobileMenu',
    GENERAL_NOTIFIER = 'generalNotifier',
    SEND_MESSAGE = 'sendMessage',
    SEND_MAIL = 'sendMail',
    ADD_ROLE = 'addRole',
    ADD_INSTRUCTOR = 'addInstructor',
    ADD_EDUCATOR = 'addEducator',
    SELECT_PERMISSION_LEVEL = 'selectPermissionLevel',
    USER_NOTE = 'userNote',
    USERS_CASE_STATUS = 'usersCaseStatus',
    CHECK_EFFICIENCY = 'checkEfficiency',
    USER_PROGRESS = 'userProgress',
    GENERAL_TEXT_AREA = 'generalTextArea',
    MEDIA_ATTACHMENT = 'mediaAttachments',
    MANAGE_WIDGETS = 'manageWidgets',
    QUESTION_EXTRA_INFO = 'questionExtraInfo',
    ADD_RESOURCE_QUESTIONS = 'addResourceQuestions',
    COURSE_REGISTER = 'courseRegister',
    DOWNLOAD = 'download',
    RESOURCE_MANAGERS = 'resourceManagers',
    AI_PROMPTER = 'aiPrompter',
    ADD_RESOURCE_MEDIA = 'addResourceMedia',
    ADD_LINK_MATERIAL = 'addLinkMatrial',
    ADD_USER = 'addUser',
    ADD_TASK = 'addTodo',
    SEND_TEXT_BLOCK_MAIL = 'sendTextBlockMail',
    VIDEO_TO_QIUZ = 'videoToQuiz',
    ADD_QUOTE = 'addQuote',
    AI_GENERATE = 'aiGenerate',
    ALL_QUOTES = 'allQuotes',
    EVENT_REGISTER = 'eventReg',
    SHOW_QUOTES_OF_QUOTEE = 'showQuotesOfQuote',
    ADD_CUSTOMER = 'addCustomer',
    ADD_NEXT_CONTACT = 'addNextContact',
    ADD_HR_NOTE = 'addHrNotes',
    ADD_ADDRESS = 'addAddress',
    CREATE_GROUP = 'addGroup',
    ADD_FILE = 'addMediaFile',
    CREATE_RESOURCE = 'createResource',
    FORM_BUILDER = 'formBuilder',
    FORM_BUILDER_LISTING = 'formBuilderListing',
    QUESTION_REVIEW = 'questionReview',
    RELATED_RESOURCES = 'relatedResources',
    SIGNOFF_FEEDBACK = 'signoffFeedback',
    COURSE_ITEM_DONE = 'courseItemDone',
    COURSE_ITEM_DONE_ALL = 'courseItemDoneAll',
    COURSE_MATERIAL_ITEM_DONE = 'courseMaterialItemDone',
    DATE_RANGE = 'dateRange',
    USER_ACTIONS_TEMPLATE = 'userActionsTemplate',
    CANCEL_COURSE_PLAY = 'cancelCoursePlay',
    GENERAL_TEXT_AREA_WITH_ACTIONS = 'generalTextAreaWithActions',
    RESOURCE_ENROLLMENT_APPROVAL = 'resourceEnrollmentApproval',
    RESOURCE_ENROLLMENT_REQUEST = 'resourceEnrollmentRequest',
    EVENT_JOIN = 'eventJoin',
    RESOURCE_DONE_ACTIVITY = 'resourceDoneActivity',
    DASHBOARD_COLUMN_SETTINGS = 'dashboardColumnSettings',
    SEND_GROUP_MAIL = 'sendGroupMail',
    UPDATE_RECURRING = 'updateRecurring',
    UPDATE_COMPETENCE = 'updateCompetence',
    TOGGLE_COMPETENCE = 'toggleCompetence',
}
export enum SideNaveActionsTypes {
    POSITIVE = 'positive',
    NEGATIVE = 'negative',
    CUSTOM = 'custom',
    PANEL_CLOSED = 'panelClosed',
    NEUTRAL = 'neutral',
}

export namespace SideNaveActionsTypes {
    export function isValid(value: any): boolean {
        return Object.values(SideNaveActionsTypes).includes(value);
    }
}

export interface SideNaveResponseData {
    action?: SideNaveActionsTypes;
    data?: any;
    clearCompData?: boolean;
}
export interface SideNaveData {
    dataType: SideNaveDataTypes;
    title?: string;
    desc?: string;
    actionIcon?: string;
    data?: any;
    ignoreDrawer?: boolean;
    returnValue?: boolean;
}
@Injectable({ providedIn: 'root' })
export class MobileService {
    protected _showingMenu: boolean;
    protected showingMenuSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
    protected sideNavSuject: Subject<SideNaveData> = new Subject();
    protected sideNavActionSuject: Subject<SideNaveActionsTypes> = new Subject();
    protected sideNavResponseData: Subject<any> = new Subject();
    protected _isMobile: BehaviorSubject<boolean> = new BehaviorSubject(false);
    protected mediaSub: Subscription;

    constructor(protected breakpoints: BreakPointRegistry, protected mediaService: MediaObserver) {
        this.mediaSub = this.mediaService.asObservable().subscribe((changes: MediaChange[]) => {
            const change = changes[0];
            this._isMobile.next(change.mqAlias === 'xs' || change.mqAlias === 'sm');
        });
    }

    public showMenu(value: boolean): void {
        this._showingMenu = value;
        this.showingMenuSubject.next(this._showingMenu);
    }

    public updateSideNavSub(value: SideNaveData): void {
        if (value.ignoreDrawer) {
            this.sideNavSuject.next(value);
            return;
        }

        if (!this._isMobile.value || (this._isMobile.value && value.dataType === SideNaveDataTypes.MOBILE_MENU)) {
            this._showingMenu = true;
            this.showingMenuSubject.next(this._showingMenu);
        }
        this.sideNavSuject.next(value);
    }
    public updateSideNavAction(value?: SideNaveActionsTypes): void {
        this.sideNavActionSuject.next(value);
    }
    public getSideNavSub(): Observable<SideNaveData> {
        return this.sideNavSuject.asObservable();
    }
    public setSideNavResponseData(data: any) {
        this.sideNavResponseData.next(data);
    }

    public getSideNavResponseData(): Observable<any> {
        return this.sideNavResponseData.asObservable().pipe(first());
    }
    public getSideNavAction(): Observable<SideNaveActionsTypes> {
        return this.sideNavActionSuject.asObservable().pipe(first());
    }

    public toggleMenu(): void {
        this._showingMenu = !this._showingMenu;
        this.showingMenuSubject.next(this._showingMenu);
    }

    public showingMenu(): Observable<boolean> {
        return this.showingMenuSubject.asObservable();
    }

    public isMobile(): Observable<boolean> {
        return this._isMobile;
    }
}
