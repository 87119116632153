<div class="group-select-wrapper" fxLayout="column" fxFlex="0 0 100%">
    <app-table-filter [inGlobalFilter]="true" color="accent" #filter>
        <app-table-search [inGlobalFilter]="true" class="align-space" searchFilterRef></app-table-search>

        <app-select-filter
            [inGlobalFilter]="true"
            class="filter-space"
            label="Filter by selection"
            i18n-label="@@filterBySelection"
            [property]="GlobalFilterProperty.SELECTED"
            [options]="itemSelectedOptions"
            selectedItemsRef
        >
        </app-select-filter>
    </app-table-filter>

    <div fxLayoutAlign="start center" fxLayoutGap="10px" class="selection-Info">
        <div fxLayoutAlign="start center" fxLayoutGap="5px" class="main">
            <span i18n="@@numberOfGroups" class="label">Number of groups:</span>
            <span class="total">{{ total }}</span>
        </div>
        <div fxLayoutAlign="start center" fxLayoutGap="5px" class="selected" *ngIf="selected">
            <span class="selectedNu" i18n="@@selected">Selected:</span>
            {{ selected }}
        </div>
    </div>
    <mat-progress-bar
        class="progress progress-table-loader"
        *ngIf="(dataSource.status | async) === 'loading'"
        mode="indeterminate"
    ></mat-progress-bar>
    <mat-table
        #table
        [dataSource]="dataSource"
        [matSortActive]="defaultSorting"
        (matSortChange)="onSortDataChages($event)"
        matSort
        [class.mobileView]="isMobile"
    >
        <ng-container matColumnDef="select">
            <mat-header-cell *matHeaderCellDef>
                <mat-checkbox
                    (click)="$event.stopPropagation()"
                    (change)="masterToggle()"
                    [checked]="isChecked()"
                    [indeterminate]="isIndeterminate()"
                >
                </mat-checkbox>
            </mat-header-cell>
            <mat-cell *matCellDef="let group">
                <mat-checkbox
                    (click)="$event.stopPropagation()"
                    (change)="selectToggle(group)"
                    [checked]="modelChecked(group)"
                    [disabled]="group.disabled"
                >
                </mat-checkbox>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@name"> Name </mat-header-cell>
            <mat-cell *matCellDef="let group">
                <span i18n="@@name" class="mobile-label"> Name </span>
                {{ group.name }}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="user_count">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@numberOfUsers">
                Number of users
            </mat-header-cell>
            <mat-cell *matCellDef="let group">
                <span i18n="@@numberOfUsers" class="mobile-label"> Number of users </span>
                {{ group.user_count }}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="created_at">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@createdAt"> Created at </mat-header-cell>
            <mat-cell *matCellDef="let group">
                <span i18n="@@createdAt" class="mobile-label"> Created at </span>
                {{ group.created_at | kdDateFormat : 'YYYY-MM-DD HH:mm' }}
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="sorting_code">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@sortingCode"> Sorting code </mat-header-cell>
            <mat-cell (click)="$event.stopPropagation()" *matCellDef="let group">
                <span i18n="@@sortingCode" *ngIf="isMobile" class="mobile-label"> Sorting code </span>
                <app-item-change-on-the-fly
                    type="number"
                    [value]="group.sorting_code ?? 0"
                    (onValueChange)="updateGroupSortingCode(group, $event)"
                ></app-item-change-on-the-fly>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="updated_at">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@updatedAt"> Updated at </mat-header-cell>
            <mat-cell *matCellDef="let group">
                <span i18n="@@updatedAt" class="mobile-label"> Updated at </span>
                {{ group.updated_at | kdDateFormat : 'YYYY-MM-DD HH:mm' }}
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="tools">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@tools"> Tools </mat-header-cell>
            <mat-cell *matCellDef="let group">
                <span i18n="@@tools" class="mobile-label"> Tools </span>
                <button mat-icon-button (click)="$event.stopPropagation(); onAddUser(group)">
                    <mat-icon>person_add_alt_1</mat-icon>
                </button>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
        <mat-row
            *matRowDef="let group; columns: columns"
            [class.disable-row]="group.disabled"
            (click)="!group.disabled && onAddUser(group)"
        ></mat-row>
    </mat-table>

    <mat-paginator [pageSize]="25" [pageSizeOptions]="[25, 50, 100]"></mat-paginator>
</div>
