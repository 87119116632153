import {
    AfterModelInit,
    Cast,
    CastType,
    IDataModel,
    Ignore,
    MethodType,
    Model,
    OnExport,
} from '@klickdata/core/application/src/model/model-interface';
import { AppScope } from '@klickdata/core/resource/src/type.scope.enum';
import { MediaType } from './media-type';

export interface MediaData extends IDataModel {
    id?: string;
    alt?: string;
    filename?: string;
    name?: string;
    src?: string;
    path?: string;
    link?: string;
    prompt?: string;
    url?: string;
    type?: string;
    mediaType?: MediaType | Plyr.MediaType;
    provider?: 'html5' | 'youtube' | 'vimeo';
    playerOptions?: any;
    media_id?: number;
    customer_id?: number;
    scope_id?: AppScope;
    thumbnail?: string;
    default_url?: string;
    fallback_url?: string;
    position?:
        | 'left top'
        | 'left center'
        | 'left bottom'
        | 'right top'
        | 'right center'
        | 'right bottom'
        | 'center top'
        | 'center center'
        | 'center bottom';

    name_translation?: { [key: string]: string };
}

export class Media extends Model<MediaData> implements AfterModelInit, OnExport {
    @Ignore(MethodType.POST)
    public id: string;
    public alt: string;
    @Ignore()
    public filename: string;
    public url: string;
    @Ignore()
    public src: string;
    public path: string;
    @Ignore()
    public mediaType: MediaType | Plyr.MediaType;
    public type: string;
    @Ignore()
    public provider: 'html5' | 'youtube' | 'vimeo';
    @Ignore()
    public playerOptions: any;
    public media_id: number;
    public customer_id: number;
    public scope_id: AppScope;
    public thumbnail: string;
    public default_url: string;
    public fallback_url: string;
    public position:
        | 'left top'
        | 'left center'
        | 'left bottom'
        | 'right top'
        | 'right center'
        | 'right bottom'
        | 'center top'
        | 'center center'
        | 'center bottom';
    @Cast(CastType.OBJECT)
    name_translation: { [key: string]: string };
    get value(): number | string {
        return this.media_id || this.fallback_url;
    }

    afterModelInit(data: MediaData): void {
        this.playerOptions = {};
        if (data.type) {
            if (data.type.indexOf('image') !== -1) {
                this.mediaType = MediaType.IMAGE;
            } else if (data.type.indexOf('pdf') !== -1) {
                this.mediaType = MediaType.PDF;
            } else if (data.type.indexOf('link') !== -1 || data.type === 'url_material') {
                this.mediaType = MediaType.LINK;
            } else if (data.type.indexOf('audio') !== -1) {
                this.mediaType = MediaType.AUDIO;
                this.provider = 'html5';
            } else if (data.type.indexOf('webm') !== -1) {
                this.mediaType = MediaType.VIDEO;
                this.provider = 'html5';
                // this.playerOptions['duration'] = 7;
            } else if (data.type.indexOf('video') !== -1) {
                this.mediaType = MediaType.VIDEO;
                this.provider = 'html5';
            } else if (
                data.type.indexOf('word') !== -1 ||
                data.type.indexOf('powerpoint') !== -1 ||
                data.type.indexOf('excel') !== -1 ||
                data.type.indexOf('application') !== -1
            ) {
                this.mediaType = MediaType.DOC;
            } else if (data.type === 'text_material') {
                this.mediaType = MediaType.TEXT;
            } else if (this.provider === 'youtube') {
                this.playerOptions['youtube'] = {
                    rel: 0, // 0, related videos will come from the same channel as the video that was just played.
                    // hl: this.localeId,
                    showinfo: 0, // This parameter is deprecated and will be ignored after September 25, 2018.
                    // enablejsapi: 1, // 1 enables the player to be controlled via IFrame or JavaScript Player
                    // origin: encodeURIComponent(this.config.config.youtubeRecipient),
                    // embed_config: encodeURIComponent(
                    //     '{primaryThemeColor: "#93CBD1",relatedChannels: ["UCobLbamr-upneN3AmWRnU-A"],autonavRelatedVideos: false,hideInfoBar: true, }'
                    // ),
                };
            } else {
                this.mediaType = MediaType.IMAGE;
            }
        }
    }

    isSupportedPromptType(): boolean {
        return (
            this.mediaType === MediaType.IMAGE ||
            this.mediaType === MediaType.VIDEO ||
            this.mediaType === MediaType.AUDIO
        );
    }

    export(): MediaData {
        return {
            src: this.src,
            type: this.type,
            mediaType: this.mediaType,
            provider: this.provider,
            filename: this.filename,
        };
    }

    beforeExport(data: MediaData): void {}
    afterExport(data: MediaData): void {
        if (this.filename) {
            data.name = this.filename;
        }
    }
}
