<h2 matDialogTitle>
    <mat-icon>warning</mat-icon>
    <span i18n>Session expiring in {{ countdown$ | async }} seconds</span>
</h2>

<mat-dialog-content i18n>
    Your token is about to expire. For your security,<br />
    we'll automatically log you out in approximatly {{ countdown$ | async }} seconds.<br />
    Choose "<span class="bold">stay logged in</span>" to continue or "<span class="bold logout">log out</span>" if
    you're done
</mat-dialog-content>

<mat-dialog-actions>
    <div class="flex-space"></div>
    <button mat-raised-button class="logout" type="button" tabindex="1" (click)="logout()" i18n>log out</button>
    <div class="space"></div>
    <button mat-raised-button type="button" tabindex="2" (click)="refeshToken()" color="primary" i18n>
        stay logged in
    </button>
</mat-dialog-actions>
