import { animate, state, style, transition, trigger } from '@angular/animations';
import { SelectionModel } from '@angular/cdk/collections';
import { DatePipe } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Inject,
    Input,
    LOCALE_ID,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import { Router } from '@angular/router';
import { Customer } from '@klickdata/core/customer';
import { K3CrmService } from '@klickdata/core/k3-crm/src/k3-crm.service';
import { MobileService } from '@klickdata/core/mobile';
import { SideNaveActionsTypes, SideNaveDataTypes } from '@klickdata/core/mobile/src/mobile.service';
import { AppScope, ResourceTag } from '@klickdata/core/resource';
import { FilterSpecs, GlobalFilterColor, GlobalFilterProperty, SelectFilterOption } from '@klickdata/core/table';
import { Task } from '@klickdata/core/task';
import { User, UserService } from '@klickdata/core/user';
import { Utils } from '@klickdata/core/util';
import { MatTableBase } from 'apps/klickdata/src/app/shared/table/mat-table-base';
import { Observable, Subject, filter, map, shareReplay, switchMap, takeUntil } from 'rxjs';
import { TaskListService } from '../task-list-service';

@Component({
    selector: 'app-task-listing-general',
    templateUrl: './task-listing-general.component.html',
    styleUrls: ['./task-listing-general.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [TaskListService, { provide: MatTableBase, useExisting: TaskListingGeneralComponent }],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class TaskListingGeneralComponent extends MatTableBase<Task> implements OnInit, AfterViewInit, OnDestroy {
    @Input() public clientUserModel = false;
    @Input() public customer: Customer;
    @Input() public user: User;
    @Input() public cacheScope = AppScope.TASK;
    @Input() public columns = [
        'select',
        'assignee',
        'priority',
        'body',
        'customer',
        'tags',
        'deadline',
        'action',
        'tools',
    ];
    @ViewChild('childTasksList') childTasksList: TaskListingGeneralComponent;
    @ViewChildren('taskCellRef') taskCellRefs: QueryList<ElementRef>;
    @ViewChildren('customerCellRef') customerCellRefs: QueryList<ElementRef>;
    @Output() onSelectCustomer: EventEmitter<Customer> = new EventEmitter<Customer>();
    selection = new SelectionModel<Task>(true, []);
    public expandedRow: Task | null;
    public expandedExtra: Task | null;
    public expandedClientExtra: Task | null;
    public displayTaskNotes: boolean;
    public taskActionsOptions: SelectFilterOption[];
    public taskPrioOptions: SelectFilterOption[];
    public taskTypesOptions: SelectFilterOption[];
    public taskDoneOptions: SelectFilterOption[];
    public myTasksOptions: SelectFilterOption[];
    public loadingTaskDone: Subject<boolean> = new Subject<boolean>();
    public isAllSelected = false;
    private lastAddedParentTaskId: number;
    private lastAddedChildTaskId: number;
    public allManagersList$: Observable<User[]>;
    public assigneeFilterSpecs: FilterSpecs = {
        property: GlobalFilterProperty.ASSIGNEES,
        label: $localize`:@@assignee:Assignee`,
        icon: 'assignment_ind',
        color: { bg: GlobalFilterColor.C2, fg: GlobalFilterColor.WHITE },
    };
    public managerFilterSpecs: FilterSpecs = {
        property: GlobalFilterProperty.MANAGERS,
        label: $localize`:@@owner:Owner`,
        icon: 'badge',
        color: { bg: GlobalFilterColor.C5, fg: GlobalFilterColor.WHITE },
    };
    public deadlineFilterSpecs: FilterSpecs = {
        property: GlobalFilterProperty.DEADLINE,
        items: [],
        composite: [
            { property: 'deadline_from', items: [] },
            { property: 'deadline_to', items: [] },
        ],
        label: $localize`:@@deadline:Deadline`,
        icon: 'event_busy',
        color: { bg: GlobalFilterColor.C4, fg: GlobalFilterColor.C1 },
        styleClass: 'global-date-filter-selector',
    };

    constructor(
        protected mobileService: MobileService,
        protected cdRef: ChangeDetectorRef,
        protected taskService: TaskListService,
        protected router: Router,
        protected k3CrmService: K3CrmService,
        protected userService: UserService,
        @Inject(LOCALE_ID) public localeId: string
    ) {
        super(cdRef, mobileService, taskService);
        this.taskActionsOptions = Utils.getTaskActionsOptions();
        this.taskPrioOptions = Utils.getTaskPrioOptions();
        this.taskTypesOptions = Utils.getTaskTypesOptions();
        this.taskDoneOptions = Utils.getTaskDoneOptions();
        this.myTasksOptions = Utils.getMyTasksOptions();
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.dataSource.status.pipe(takeUntil(this.destroy)).subscribe((loaded) => {
            if (loaded === 'loaded') {
                this.updateTaskCellUI();
            }
        });
        this.mobileService
            .isMobile()
            .pipe(
                takeUntil(this.destroy),
                filter((isMob) => isMob && this.columns.indexOf(this.clientUserModel ? 'name' : 'body') !== -1)
            )
            .subscribe(() => {
                this.columns.splice(this.columns.indexOf(this.clientUserModel ? 'name' : 'body'), 1);
                this.columns.unshift(this.clientUserModel ? 'name' : 'body');
                this.cdr.markForCheck();
            });
    }

    updateTaskCellUI() {
        setTimeout(() => {
            this.taskCellRefs.forEach((item) => {
                if (item.nativeElement.clientHeight > 60) {
                    item.nativeElement.classList.add('add-dots');
                }
            });
            this.customerCellRefs.forEach((item) => {
                if (item.nativeElement.clientHeight > 80) {
                    item.nativeElement.classList.add('customer-cell-exceeds');
                }
            });
        }, 500);
    }
    ngAfterViewInit(): void {
        super.ngAfterViewInit();
        this.allManagersList$ = this.userService
            .getLightMasterUsers({
                limit: 20,
                userRole: 'superadmin',
                // role: ResourceStaffRoles.MANAGER,
            })
            .pipe(shareReplay());
    }

    onTagClick(tag: ResourceTag) {
        this.filter.filterByModel(tag.getFilter(), true);
    }
    onSelectSignature(manager: User, task: Task) {
        this.taskService
            .update({ id: task.id, staff: { manager: [manager.id] } })
            .pipe(takeUntil(this.destroy))
            .subscribe((updatedTask) => {
                task.loading = false;
                task.priority = updatedTask.priority;
                this.cdRef.markForCheck();
            });
    }
    onSelectAssignee(user: User) {
        this.filter.filterByModel(
            new User({
                id: user.id,
                name: `${user.fname} ${user.lname}`,
                staffRoleProperty: this.GlobalFilterProperty.ASSIGNEES,
            }).getFilter()
        );
    }
    onCustomerClick(id: number) {
        // this.onSelectCustomer.emit(new Customer(customer));
        this.router.navigate(['/master/customers/customer-manager/', id]);
    }
    updateTaskPrio(task: Task, prio: any) {
        this.taskService
            .update({ id: task.id, priority: prio })
            .pipe(takeUntil(this.destroy))
            .subscribe((updatedTask) => {
                task.loading = false;
                task.priority = updatedTask.priority;
                this.cdRef.markForCheck();
            });
    }
    updateTaskTags(task: Task, tagsIds: number[]) {
        this.taskService
            .update({ id: task.id, tag_ids: tagsIds }, ['tags', 'managers', 'customers'])
            .pipe(takeUntil(this.destroy))
            .subscribe((updatedTask) => this.dataSource.replace(updatedTask));
    }
    changeTaskDone(task: Task, status: boolean) {
        this.taskService
            .updateTaskDone([task.id], status)
            .pipe(takeUntil(this.destroy))
            .subscribe(() => {
                task.loading = false;
                this.dataSource.removeById(task.id);
                this.cdRef.markForCheck();
            });
    }
    markSelectedAsDone() {}
    getInfo(task: Task): string {
        return [
            $localize`Created: ` +
                new DatePipe(this.localeId).transform(
                    task.created_at.toString(),
                    this.localeId === 'en' ? 'MMM d, y HH:mm' : 'd MMM y HH:mm'
                ),
            $localize`Updated: ` +
                new DatePipe(this.localeId).transform(
                    task.updated_at.toString(),
                    this.localeId === 'en' ? 'MMM d, y HH:mm' : 'd MMM y HH:mm'
                ),
            task.managers && task.managers.length ? $localize`Owner: ` + this.getTaskOwners(task) : '',
        ].join('\n');
    }
    private getTaskOwners(task: Task): string {
        return task.managers
            .map((manager) => {
                return manager.fname + ' ' + manager.lname;
            })
            .join(', ');
    }
    delete(task?: Task) {
        this.mobileService.updateSideNavSub({
            dataType: SideNaveDataTypes.GENERAL_NOTIFIER,
            data: {
                icon: 'delete',
                title: task ? $localize`Delete task` : $localize`Delete tasks`,
                contentBody: $localize`You are about to delete task. Please use caution and make sure that you really intend to delete before confirming delete below.`,
                positiveBtn: $localize`Delete`,
                negativeBtn: $localize`Cancel`,
            },
        });
        this.mobileService
            .getSideNavAction()
            .pipe(
                filter((action) => action === SideNaveActionsTypes.POSITIVE),
                switchMap((res) => {
                    if (res) {
                        return this.taskService.destroy(
                            task ? [task.id] : this.selection.selected.map((task) => task.id)
                        );
                    }
                })
            )
            .subscribe((action) => {
                if (action) {
                    task
                        ? this.dataSource.removeById(task.id)
                        : this.dataSource.removeByIds(this.selection.selected.map((task) => task.id));
                    this.selection.clear();
                }
            });
    }

    addTask() {
        this.mobileService.updateSideNavSub({
            dataType: SideNaveDataTypes.ADD_TASK,
            data: {
                user: this.user || this.authUser,
                type: 'addTask',
                customer: this.customer,
            },
        });
        this.mobileService
            .getSideNavResponseData()
            .pipe(
                filter((data) => data.type === 'addTask'),
                takeUntil(this.destroy),
                map((data) => data.task)
            )
            .subscribe((data: Task) => {
                if (data && this.lastAddedParentTaskId != data?.id) {
                    this.dataSource.add(data);
                    this.updateTaskCellUI();
                    this.lastAddedParentTaskId = data.id;
                }
            });
    }
    addChildTask(task: Task) {
        this.mobileService.updateSideNavSub({
            dataType: SideNaveDataTypes.ADD_TASK,
            data: {
                user: this.user || this.authUser,
                type: 'addTask',
                parentId: task.id,
            },
        });
        this.mobileService
            .getSideNavResponseData()
            .pipe(
                filter((data) => data.type === 'addTask'),
                takeUntil(this.destroy),
                map((data) => data.task)
            )
            .subscribe((data: Task) => {
                if (data && this.lastAddedChildTaskId != data?.id) {
                    task.children_count++;
                    this.cdRef.markForCheck();
                    setTimeout(() => {
                        this.childTasksList?.dataSource.add(data);
                        this.childTasksList?.updateTaskCellUI();
                        task.displayTaskNotes = false;
                        this.lastAddedChildTaskId = data.id;
                    }, 500);
                }
            });
    }
    UpdateTask(task: Task) {
        this.mobileService.updateSideNavSub({
            dataType: SideNaveDataTypes.ADD_TASK,
            data: {
                type: 'updateTask',
                task: task,
            },
        });
        this.mobileService
            .getSideNavResponseData()
            .pipe(
                filter((data) => data.type === 'updateTask'),
                takeUntil(this.destroy)
            )
            .subscribe((data: any) => {
                if (data.task) {
                    this.dataSource.replace(data.task);
                    this.updateTaskCellUI();
                }
            });
    }
    updateDeadline(event: Date, task: Task) {
        const chosenDate = new DatePipe('en').transform(event, 'yyyy-MM-dd HH:mm');
        this.taskService
            .update({ id: task.id, deadline: chosenDate.toString() })
            .pipe(takeUntil(this.destroy))
            .subscribe((updatedTask) => {
                task.loading = false;
                task.deadline = updatedTask.deadline;
                this.cdr.markForCheck();
            });
    }
    editUser(id: number) {
        this.router.navigate(['/master/customers/k3-connections/connection/', id]);
    }
    onModelNameClick(task: Task) {
        if (task.client_contact && !task.user_contact) {
            this.onCustomerClick(task.client_contact.id);
        } else if (task.user_contact) {
            this.editUser(task.user_contact.id);
        }
    }
    ngOnDestroy() {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
